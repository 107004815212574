.editListCard {
  margin-top: 12vh;
  margin-bottom: 6%;
  padding: 5vh;
  padding-bottom: 3vh;
  width: 80%;
  background-color: lightgray;
  border-radius: 15px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
}

.scrollBox {
  overflow: auto;
  min-height: 160px;
  max-height: 40vh;
  border-radius: 10px;
}

.saveBtn {
  width: 120px;
  padding: 10px;
  margin-top: 20px;
}

/* removes dropdown caret */
/* .dropdown-toggle::after {
  display: none;
} */

.editListName {
  border: transparent;
  background-color: transparent;
  width: 100%;

  display: block;
  font-size: 2em;
  margin-bottom: 0.3em;
  font-weight: bold;
  color: var(--bs-body-color);
}

.listDetailsBox {
  resize: none;
  border: transparent;
  height: auto;
  background-color: transparent;
  width: 100%;
  margin-bottom: 15px;
  color: var(--bs-body-color);
}

.addRemoveBtn {
  border-radius: 5px;
  margin-top: 5px;
  width: 40px;
  text-align: center;
  padding: 10px;
}

.editHeadingBlock {
  display: inline-block;
  margin-bottom: 5px;
  width: 90%;
  padding: 10px;
  padding-left: 10px;
  padding-bottom: 20px;
  background-color: transparent;
  max-height: 3em;
}

.editHeadingUnderline {
  width: 70%;
  margin-bottom: 0;
}

.editItemBlock {
  display: inline-block;
  margin-bottom: 10px;
  width: 90%;
  padding: 10px;
  padding-left: 20px;
  background-color: #ededed;
  border-radius: 10px;
  max-height: 3em;
}

.inputBox {
  border: transparent;
  background-color: transparent;
  width: 100%;
  color: var(--bs-body-color);
}

textarea:focus,
input:focus {
  outline: none;
}

.itemRow {
  margin-left: 0;
  width: 100%;
}

.editButtonBlock {
  display: inline-block;
  vertical-align: top;
  width: 5%;
  margin-left: 5px;
}

@media screen and (max-width: 800px) {
  .editListCard {
    margin-bottom: 6%;
    padding: 2vh;
    padding-bottom: 2vh;
    width: 90%;
  }

  .editListName {
    width: 100%;

    display: block;
    font-size: 1.5em;
    margin-bottom: 0.3em;
    font-weight: bold;
  }

  .editItemBlock {
    margin-bottom: 10px;
    width: 65%;
    padding: 5px;
    border-radius: 10px;
    max-height: 3em;
  }

  .editHeadingBlock {
    margin-bottom: 10px;
    width: 65%;
    padding: 5px;
    padding-bottom: 20px;
    max-height: 3em;
  }

  .editHeadingUnderline {
    width: 80%;
  }

  .saveBtn {
    margin-right: 12px;
  }
}

@media screen and (min-width: 800px) and (max-width: 1100px) {
  .editHeadingBlock {
    width: 80%;
  }

  .editItemBlock {
    width: 80%;
  }
}

.previewLeftContainer {
  margin-top: 15vh;
  margin-left: 20%;
  width: 40vw;
  height: auto;
}

/* To use or not to use? */
.previewListCard {
  padding: 5vh;
  padding-bottom: 3vh;
  background-color: lightgray;
  border-radius: 15px;
}

.itemPreviewShell {
  margin-bottom: 10px;
  margin-left: 0px;
  width: 98%;
  padding: 10px;
  padding-left: 20px;
  background-color: #ededed;
  border-radius: 10px;
}

.headingPreviewShell {
  margin-bottom: 5px;
  margin-left: 0px;
  width: 95%;
  padding: 10px;
  padding-left: 10px;
  background-color: transparent;
  max-height: 4em;
  font-weight: 600;
  text-decoration: underline;
}

.listScroll {
  overflow: auto;
  max-height: 50vh;
  border-radius: 10px;
  padding-right: 10px;
}

.previewRightContainer {
  padding: 10vh;
  padding-bottom: 3vh;
  background-color: transparent;
  width: 75%;
}

.shareBtn {
  width: 30%;
  min-width: 190px;
  margin-top: 10vh;
  padding: 10px;
}

.copyBtn {
  width: 30%;
  min-width: 165px;
  margin-top: 25vh;
  padding: 10px;
}

.listNamePreview {
  border: transparent;
  background-color: transparent;
  width: 100%;

  display: inline;
  font-size: 2em;
  margin-bottom: 0.3em;
  font-weight: bold;
  color: var(--bs-body-color);
}

.listDetailsBox {
  resize: none;
  border: transparent;
  height: auto;
  background-color: transparent;
  width: 100%;
  margin-bottom: 15px;
  color: var(--bs-body-color);
}

.wrapperDiv {
  display: flex;
  justify-content: center;
}

.listSide {
  width: 50%;
}

.buttonSide {
  width: 50%;
}

.copyButtonWrapper {
  display: flex;
  justify-content: center;
}

.shareButtonWrapper {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 300px) and (max-width: 1030px) {
  .wrapperDiv {
    display: block;
    justify-content: center;
  }

  .listSide {
    width: 90%;
    margin: auto;
  }

  .previewLeftContainer {
    margin: auto;
    margin-top: 10vh;
    width: 90%;
    height: auto;
  }

  .listNamePreview {
    font-size: 1.5em;
  }

  .buttonSide {
    width: 90%;
    margin: auto;
  }

  .previewRightContainer {
    padding: 0;
    margin: auto;
    margin-top: 30px;
    background-color: transparent;
    width: 100%;
    text-align: center;
  }

  .copyButtonWrapper {
    display: inline-block;
    margin: 0;
  }

  .copyBtn {
    width: 30%;
    min-width: 130px;
    margin-top: 0;
    margin-right: 10px;
    padding: 5px;
    font-size: 16px;
  }

  .shareButtonWrapper {
    display: inline-block;
    margin: 0;
  }

  .shareBtn {
    width: 30%;
    min-width: 135px;
    margin-top: 0;
    margin-left: 10px;
    padding: 5px;
    font-size: 16px;
  }
}

@media screen and (max-height: 700px) {
  .previewRightContainer {
    padding: 0;
    margin: auto;
    margin-top: 30px;
    background-color: transparent;
    width: 100%;
    text-align: center;
  }
}

.listCard {
  margin: 6%;
  margin-top: 12vh;
  padding: 5vh;
  padding-bottom: 3vh;
  background-color: lightgray;
  border-radius: 15px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
}

.listDetailsBox {
  resize: none;
  border: transparent;
  height: auto;
  background-color: transparent;
  width: 100%;
  margin-bottom: 15px;
  color: var(--bs-body-color);
}

.listScrollBox {
  overflow: auto;
  max-height: 45vh;
  border-radius: 10px;
}

.headingListShell {
  margin-bottom: 10px;
  margin-left: 0px;
  min-width: 100%;
  padding-left: 10px;
  background-color: transparent;
  max-height: 3em;
  font-weight: 600;
  text-decoration: underline;
  color: var(--bs-body-color);
}

.itemListShell {
  margin-bottom: 10px;
  margin-left: 0px;
  padding: 10px;
  padding-left: 20px;
  background-color: #ededed;
  border-radius: 10px;
  color: var(--bs-body-color);
  position: relative;
  cursor: pointer;
  /* max-width: 100%; */
}

/* .itemListShell:hover {
  background-color: #e3e3e3;
} */

.itemName {
  display: inline-block;
  vertical-align: top;
  color: var(--bs-body-color);
  transition: color 0.25s;
  width: 80%;
  height: 100%;
  word-wrap: break-word;
}

.checkDiv {
  display: inline-block;
  vertical-align: top;
  /* border: 1px solid red; */
  width: 20%;
  height: 100%;
}

/* Check Box  */

.checky {
  display: none;
}

label {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  position: absolute;
  right: 0;
  top: 50%;
  margin-right: 10px;
  background-color: lightgray;
  transform: translateY(-50%);
  border-radius: 50%;
  cursor: pointer;
  transition: 0.2s ease transform, 0.2s ease background-color,
    0.2s ease box-shadow;
  overflow: hidden;
  z-index: 1;
}

label:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  background-color: #ededed;
  transform: translateY(-50%);
  border-radius: 50%;
  transition: 0.2s ease width, 0.2s ease height;
}

label:hover:before {
  width: 26px;
  height: 26px;
}

label:active {
  transform: translateY(-50%) scale(0.9);
}

#tick_mark {
  top: -1px;
  right: 0;
  left: 0;
  width: 25px;
  height: 25px;
  margin: 0 auto;
  margin-left: 5px;
  transform: rotateZ(-40deg);
}

#tick_mark:before,
#tick_mark:after {
  content: "";
  position: absolute;
  background-color: #fff;
  border-radius: 1px;
  opacity: 0;
  transition: 0.2s ease transform, 0.2s ease opacity;
}

#tick_mark:before {
  left: 0;
  bottom: 0;
  width: 5px;
  height: 15px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  transform: translateY(-68px);
}

#tick_mark:after {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  transform: translateX(78px);
}

.checky:checked + label {
  background-color: rgba(var(--bs-checkbox-color));
}

.checky:checked + label:before {
  width: 0;
  height: 0;
}

.checky:checked + label #tick_mark:before,
.checky:checked + label #tick_mark:after {
  transform: translate(0);
  opacity: 1;
}

.bottomBtn {
  width: 100px;
  padding: 10px;
  margin-top: 20px;
  margin-right: 20px;
  text-align: center;
}

.listNameDisp {
  border: transparent;
  background-color: transparent;
  width: 100%;

  display: block;
  font-size: 2em;
  margin-bottom: 0.3em;
  font-weight: bold;
  color: var(--bs-body-color);
}

@media screen and (max-width: 400px) {
  .listCard {
    padding: 2vh;
    margin-bottom: 1%;
    max-width: 90%;
  }

  .listScrollBox {
    max-height: 50vh;
    /* border: 1px solid purple; */
  }

  .listNameDisp {
    font-size: 1.5em;
  }

  .headingListShell {
    padding-left: 0px;
  }

  .bottomBtn {
    width: 85px;
    padding: 10px;
    margin-top: 20px;
    margin-right: 15px;
  }
}

@media screen and (min-width: 400px) and (max-width: 600px) {
  .listCard {
    padding: 3vh;
    margin-bottom: 1%;
    max-width: 90%;
  }

  .listScrollBox {
    max-height: 40vh;
    /* border: 1px solid red; */
  }

  .listNameDisp {
    font-size: 1.5em;
  }

  .headingListShell {
    padding-left: 0px;
  }

  .bottomBtn {
    width: 85px;
    padding: 10px;
    margin-top: 20px;
    margin-right: 15px;
  }
}

@media screen and (max-height: 700px) {
  .listCard {
    margin-top: 10vh;
    max-width: 90%;
  }

  .listScrollBox {
    max-height: 45vh;
    /* border: 1px solid blue; */
  }
}

@media screen and (max-height: 700px) and (max-width: 340px) {
  .listCard {
    margin-top: 10vh;
    max-width: 90%;
  }

  .listScrollBox {
    max-height: 35vh;
  }
}

@media screen and (min-height: 700px) and (max-height: 900px) {
  .listCard {
    margin-top: 10vh;
    max-width: 90%;
  }

  .listScrollBox {
    max-height: 45vh;
    /* border: 1px solid blue; */
  }
}

@media screen and (min-width: 2500px) {
  .listCard {
    padding: 8vh;
    margin-top: 12vh;
    max-width: 70%;
  }

  .listScrollBox {
    max-height: 30vh;
    /* border: 1px solid blue; */
  }

  .listNameDisp {
    font-size: 3em;
  }

  .bottomBtn {
    width: 80px;
    padding: 10px;
    margin-top: 20px;
    margin-right: 20px;
  }
}

@media screen and (min-height: 830px) and (max-width: 430px) {
  .listScrollBox {
    max-height: 55vh;
  }
}

@media screen and (max-width: 380px) {
  .bottomBtn {
    width: 75px;
    padding: 7px;
    margin-top: 20px;
    margin-right: 10px;
  }
}
